import React from "react"
import "../../../styles/main.scss"
import SEO from "../../../components/seo"
import { Skeleton } from "../../../components/layout/Skeleton"
import styled from "styled-components"
import Section from "../../../components/course/Section"
import CategoryLabel from "../../../components/CategoryLabel"

const Container = styled(Section)`
  max-width: 780px !important;
`

const List = styled.ul`
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
`

const GroupsRules = () => {
  return (
    <>
      <SEO
        title={`Regulamin grup wsparcia dla kobiet w ciąży | Nasze Pierwsze Dziecko"`}
        description={`Regulamin grup wsparcia dla kobiet w ciąży. Bez spamu, ankiet i postów sprzedażowych. Zasady wzajemnej dyskusji."`}
      />
      <Skeleton>
        <Container className={"container"}>
          <CategoryLabel title={"Regulamin grup wsparcia"} />
          <List>
            <li>
              Do grupy dołączyć moga tylko kobiety w ciązy z terminem zgodnym z
              grupą do której dołączają.
            </li>
            <li>
              Reklama innych grup, sklepów, usług, konkursów, zbiórek
              charytatywnych, wydarzeń własnych stron na FB, profili
              społecznościowych (instagram, snapchat itd.) lub blogów jest
              zabroniona. Osoby dodające takie treści będą usuwane i blokowane
              na grupie. Nie stanowi reklamy usług udzielanie informacji
              dotyczących produktów lub działalności w szczególności w
              odpowiedzi na zapytania innych członków grupy.
            </li>
            <li>
              Osoby, które łamią zasady kultury osobistej i netykiety na forum
              publicznym, będą usuwane z grupy.
            </li>
            <li>
              Autorzy postów zawierających wulgaryzmy nie będą informowani o
              kasowaniu tychże wypowiedzi.
            </li>
            <li>
              <span className="is-bold">Bądź Miła!</span> Wprowadzaj pozytywną i
              miłą atmosferę; w grupie nie będzie tolerowane negatywne
              nastawienie, obraźliwe komentarze, sianie plotek, oszczerstw,
              publicznego linczu na kimś, czy tworzenie psychodramatów (każda
              taka osoba wylatuje z grupy!). Nie traktuj nikogo lekceważąco lub
              z góry – postaraj się, by inni czuli się w grupie, jak u siebie w
              domu (lub u przyjaciół na kawie!), bądź tolerancyjna, pomocna i
              uśmiechnięta (daj to odczuć drugiej osobie).{" "}
              <span className="is-bold">
                TRAKTUJ INNYCH TAK, JAK SAMA CHCIAŁABYŚ BYĆ TRAKTOWANA!
              </span>
            </li>
            <li>
              Posty sprzedażowe lub reklamujące sprzedaż na serwisach (typu:
              sprzedam Golfa i link do allegro) są zabronione. Osoby dodające
              takie treści będą usuwane i blokowane na grupie. Posty z
              PROMOCJAMI, okazjami, zniżkami, czy akcjami tematycznymi w
              marketach SĄ mile widziane.
            </li>
            <li>Treści rasistowskie i ksenofobiczne są zakazane.</li>
            <li>
              Korzystaj z wyszukiwarki na grupie (ikonka lupki po lewej stronie)
              przed dodaniem nowego wpisu.
            </li>
            <li>Daj innym więcej niż od nich weźmiesz; doradzaj, inspiruj!</li>
            <li>
              Pamiętaj! Grupa i dziewczyny na grupie nie zastąpią Ci lekarza! W
              przypadku wątpliwości co do Twojego stanu zdrowia, lub stanu
              dziecka - skontaktuj się z lekarzem!
            </li>
          </List>
        </Container>
      </Skeleton>
    </>
  )
}

export default GroupsRules
